import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Theme from '../components/Theme/theme.js'
import './contact.css';

import Typist from 'react-typist';
import BlankWindow from '../components/BlankWindow/blank-window.js'

class ContactIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
    const pageTitle = "Contact"
    const pageDescription = "Get a hold me..."
    let yCount = 60;
    return (
      <Layout location={this.props.location}>
        <Theme />
        <Helmet
          title={`${pageTitle} - ${siteTitle}`}>
          <meta name="description" content={pageDescription} />
          <meta property="og:url" content={`${siteUrl}${this.props.location.pathname}`} />
          <meta property="og:title" content={`${pageTitle} - ${siteTitle}`} />
          <meta property="og:description" content={pageDescription} />
        </Helmet>

        <div className="index-title centered-title preload container">
          <h1 className="title">
            <Typist 
            startDelay={750}
            avgTypingDelay={130}
            cursor={{
                blink: false,
                element: '_',
                hideWhenDone: true,
                hideWhenDoneDelay: 150
            }}>Contact</Typist>
          </h1>
        </div>

        <div className="contact-index-container container">
        <BlankWindow 
          bounds={'.wrapper'}
          width={275}
          height={241}
          minWidth={275}
          minHeight={241}
          // lockAspect={1.141078838174274}
          className="window"
          dragHandleClassName={"title-bar"}
          yOffset={yCount + Math.floor(Math.random() * 100)}
          >
            <div className="title-bar">
              <div className="title-bar-text">Contact Me</div>
              <div className="title-bar-controls">
              {/*
                <button aria-label="Minimize"></button>
                <button aria-label="Maximize"></button>
                <button aria-label="Close"></button>
             */}
              </div>
            </div>          
            <div className="window-body ">
              <form name="contact" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
               <div className="field-row" >
                 <label htmlFor="name" style={{ width: '100px' }}>Name</label>
                 <input id="name" name="name" type="text" required />
               </div>
               <div className="field-row" >
                 <label htmlFor="email" style={{ width: '100px' }}>Email Address</label>
                 <input id="email" name="email" type="email" required />
               </div>
               <div className="field-row-stacked" >
               <label htmlFor="message" style={{ width: '100px' }}>Message</label>
                 <textarea id="message" name="message" rows="5" required>
                 </textarea>
               </div>
               <hr className="field-hr" />
               <div className="field-row field-row-footer">               

               
               <button type="submit"><u>S</u>ubmit</button>
               
               </div>
              </form>
            </div>
        </BlankWindow>
        </div>
      </Layout>
    )
  }
}

export default ContactIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`
